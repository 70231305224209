<template>
	<div class="guibox clearfix">
		<div class="guileft">
			<el-tree :data="data" highlight-current node-key="regioncode" show-checkbox
				:default-checked-keys="expandedKeys" :default-expanded-keys="expandedKeys" ref="tree"
				@check="handleCheckChange" :props="defaultProps"></el-tree> <!-- @node-click="handleNodeClick" -->
		</div>

		<div class="guiright" v-loading="loading">
			<div class="topinfo" v-if="visualAngle == 'shequ'">
				<div class="fangwuinfo fangwuinfo1">
					<div class="commontitle" style="margin-bottom: 10px;">房屋基本情况</div>
					<div class="box">
						<div class="item item1">
							<p class="title">房屋总数</p>
							<p class="num"><span>{{xiaoqunumsSum}}</span> 个<span>/{{xiaoquHouseSum}}</span> 间</p>
							
							<div class="detail">
								<p style="padding-right: 20px;">居住房屋<span>{{xiaoquLiveNum}}</span></p>
								<p>其他房屋<span>{{xiaoquOtherNum}}</span></p>
							</div>
						</div>
						<div :class="index == 0?'item item2':'item item3'" v-for="(item,index) in xiaoqunums"
							:key="index">
							<p class="title">房屋总数</p>
							<p class="num"><span>{{item.value}}</span> 个<span>/{{ item.housenums }}</span> 间</p>
						</div>
					</div>
				</div>
				<div class="renkouinfo renkouinfo1">
					<div class="commontitle" style="margin-bottom: 10px;">人口分布</div>
					<div class="box">
						<div class="item1">
							<p><span class="name">总户数 </span><span
									class="num">{{personfenbu.hujihushu + personfenbu.huzairenbuzaihushu + personfenbu.renbujingchangzaihushu + personfenbu.liudonghushu}}</span>户
							</p>
							<div class="fenge"></div>
							<p><span class="name">总人数 </span><span
									class="num">{{personfenbu.huji + personfenbu.feihuji + personfenbu.zuke + personfenbu.huzairenbuzai}}</span>人
							</p>
						</div>
						<div class="item2">
							<div class="biao">农村组</div>
							<p><span class="name">总房间数 </span><span class="num">{{personfenbu.nongcunzuhushu}}</span>户
							</p>
							<div class="fenge"></div>
							<p v-if="personfenbu.nongcunzuhushu - personfenbu.liudonghushu > 0"><span class="name">空余数
								</span><span
									class="num">{{ personfenbu.nongcunzuhushu - personfenbu.liudonghushu }}</span>户</p>
						</div>
						<div class="item3" style="margin-top: -31px !important;">
							<p>户籍人口：<span>{{personfenbu.huji + personfenbu.huzairenbuzai + personfenbu.feihuji}}人</span> <span>{{personfenbu.hujihushu + personfenbu.huzairenbuzaihushu + personfenbu.renbujingchangzaihushu}}户</span></p>
							<div class="fenge"></div>
							<p>户在人在：<span>{{personfenbu.huji}}人</span> <span>{{personfenbu.hujihushu}}户</span></p>
							<div class="fenge"></div>
							<p>户在人不在：<span>{{personfenbu.huzairenbuzai}}人</span>
								<span>{{personfenbu.huzairenbuzaihushu}}户</span>
							</p>
							<div class="fenge"></div>
							<p>户在人不经常在：<span>{{personfenbu.feihuji}}人</span>
								<span>{{personfenbu.renbujingchangzaihushu}}户</span>
							</p>
							<div class="fenge"></div>
							<p>流动人口：<span>{{personfenbu.zuke}}人</span> <span>{{personfenbu.liudonghushu}}户</span></p>
						</div>
					</div>
				</div>
			</div>
			<div class="topinfo" v-else>
				<div class="fangwuinfo fangwuinfo2">
					<div class="fangwu">
						<div class="commontitle" style="margin-bottom: 10px;">房屋基本情况</div>
						<div class="box">
							<div class="item item1">
								<p class="title">房屋总数</p>
								<p class="num"><span>{{roomnum}}</span> 户</p>
								<div class="detail">
									<p style="padding-right: 20px;">居住房屋<span>{{xiaoquLiveNum}}</span></p>
									<p>其他房屋<span>{{xiaoquOtherNum}}</span></p>
								</div>
							</div>
						</div>
					</div>
					<div class="gridbox">
						<div class="gridtitle">网格员</div>
						<div class="gridlist">
							<div v-for="(item,index) in gridmanlist" :key="index" class="gridman">
								<img :src="item.photopath" alt="">
								<div>
									<p class="name" :style="{backgroundImage:'url(' + item.sexlogo + ')'}">{{item.name}}
									</p>
									<p class="phone">{{item.phone}}</p>
								</div>
							</div>
						</div>
					</div>

				</div>
				<div class="renkouinfo renkouinfo2">
					<div class="commontitle" style="margin-bottom: 10px;">人口分布</div>
					<div class="box">
						<div class="item1">
							<p><span class="name">总户数 </span><span
									class="num">{{personfenbu.hujihushu + personfenbu.huzairenbuzaihushu + personfenbu.renbujingchangzaihushu + personfenbu.liudonghushu}}</span>户
							</p>
							<div class="fenge"></div>
							<p><span class="name">总人数 </span><span
									class="num">{{personfenbu.huji + personfenbu.feihuji + personfenbu.zuke + personfenbu.huzairenbuzai}}</span>人
							</p>
						</div>
						<div class="item3" style="margin-top: -31px !important;">
							<p>户籍人口：<span>{{personfenbu.huji + personfenbu.huzairenbuzai + personfenbu.feihuji}}人</span> <span>{{personfenbu.hujihushu + personfenbu.huzairenbuzaihushu + personfenbu.renbujingchangzaihushu}}户</span></p>
							<div class="fenge"></div>
							<p>户在人在：<span>{{personfenbu.huji}}人</span> <span>{{personfenbu.hujihushu}}户</span></p>
							<div class="fenge"></div>
							<p>户在人不在：<span>{{personfenbu.huzairenbuzai}}人</span>
								<span>{{personfenbu.huzairenbuzaihushu}}户</span>
							</p>
							<div class="fenge"></div>
							<p>户在人不经常在：<span>{{personfenbu.feihuji}}人</span>
								<span>{{personfenbu.renbujingchangzaihushu}}户</span>
							</p>
							<div class="fenge"></div>
							<p>流动人口：<span>{{personfenbu.zuke}}人</span> <span>{{personfenbu.liudonghushu}}户</span></p>
						</div>
					</div>
				</div>
			</div>
			<div class="centerinfo">
				<div class="centerleft">
					<div class="box box1">
						<div class="commontitle" style="margin-bottom: 10px;">房屋类型数</div>
						<div class="content" id="houseTypeChart"></div>
					</div>
					<div class="box box2">
						<div class="commontitle">性别比例</div>
						<div class="content" id="buildSexRatioChart"></div>
					</div>
					<div class="box box3">
						<div class="commontitle" style="margin-bottom: 10px;">走访记录</div>
						<div class="content">
							<div class="toubu">
								<div class="kuang one">姓名</div>
								<div class="kuang two">巡防内容</div>
								<div class="kuang three">巡防地址</div>
							</div>
							<div class="neirong">
								<div class="item" v-for="(item,index) in zoufangList" :key="index">
									<div class="kuang one">{{item.manname}}</div>
									<div class="kuang two">{{item.dologtypename}}</div>
									<div class="kuang three">{{item.dologdesc}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="centerright" v-if="visualAngle == 'shequ'">
					<div class="box1">
						<div class="commontitle" style="margin-bottom: 10px;">社区详情</div>
						<div class="content">
							<div class="item item1">网格数 <span>{{gridlistNum}}个</span></div>
							<div class="item item2">网格员 <span>{{gridmanlist.length}}人</span></div>
						</div>
					</div>
					<div class="box2">
						<div class="commontitle">迁入信息</div>
						<div class="qianru" id="moveIn"></div>
						<div class="commontitle">迁出信息</div>
						<div class="qianchu" id="moveOut"></div>
					</div>
				</div>
				<div class="centerright" v-if="visualAngle == 'xiaoqu'">
					<div class="box3">
						<div class="commontitle" style="margin-bottom: 10px;">小区详情</div>
						<div class="content">
							<div class="zuobian" v-if="buildlist.length">
								<div class="item1">楼栋数 <span>{{ loudongnum }}个</span></div>
								<div class="item2">总单元数 <span>{{ totalUnitNums  }}个</span></div>
							</div>
							<div class="youbian" v-if="gridlist.length">
								<div class="xiaoqulist">
									<div class="item" v-for="(net,index) in gridlist" :key="index">
										小区：<span>{{ net.regionname }}</span></div>
								</div>
								<div class="wanggelist">
									<div class="item" v-for="(net,index) in gridlist" :key="index">
										所属网格：<span>冯墙社区{{ net.gridtotal }}网格</span></div>
								</div>
							</div>
						</div>
					</div>
					<div class="box4">
						<div v-for="(item,index) in buildlist" :key="index">
							<div class="bigtitle">
								<div class="xiaoquname">{{item.regionname}}</div>
								<div class="shangpin" v-if="item.businessFlag">
									<div></div>
									<p>商业楼</p>
								</div>
							</div>
							<div class="danyuanlist">
								<div v-for="(build,index2) in item.detail" :key="index2"
									:class="build.shangyeflag == 1? 'item shangpin':'item'"
									@click="getBuildData(build.concatcode, build.buildname)">
									<div class="mingcheng">
										<p>{{ build.buildname }}</p>
										<div class="beijing"></div>
									</div>
									<div class="detail" v-if="build.mapinfo">
										<div>
											<p>单元数</p>
											<p>{{ build.mapinfo.unitnums }}</p>
										</div>
										<div :class="build.mapinfo.menmiannums == 0?'':'teshu'">
											<p>门面房</p>
											<p>{{ build.mapinfo.menmiannums }}</p>
										</div>
										<div>
											<p>人数</p>
											<p>{{ build.mapinfo.personnums }}</p>
										</div>
										<div>
											<p>房间数</p>
											<p>{{ build.mapinfo.nowhousenum }}</p>
										</div>
										<div :class="build.mapinfo.dixiashinums == 0?'':'teshu2'">
											<p>地下室</p>
											<p>{{ build.mapinfo.dixiashinums }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						
					</div>
				</div>
				<div class="centerright" v-if="visualAngle == 'dong' || visualAngle == 'room'">
					<div class="box5">
						<div class="commontitle" style="margin-bottom: 10px;">楼栋详情</div>
						<div class="content">
							<div class="louname">
								<p>{{ buildName }}</p>
							</div>
							<div class="danyuanlist" v-if="housenumlist.length">
								<div class="total">单元数<span>{{ housenumlist.length }}个</span></div>
								<div v-for="(house,index) in housenumlist" :key="house.housenum"
									@click="getFloorData(house.housenum, index)"
									:class="curIndex == index? 'item selected':'item'">{{ house.housenum }}单元</div>
							</div>
						</div>

					</div>
					<div class="box6">
						<div class="fenleilist">
							<div class="item" v-for="(item,index) in fenleilist" :key='index'>
								<div :style="{backgroundColor:item.color}"></div>
								<p>{{item.name}}</p>
							</div>
						</div>
						<div class="fangwulist">
							<div class="item" v-for="floor in houseFloorList" :key="floor.floornum">
								<div class="floor" v-if="floor.floornum">{{ floor.floornum }}层</div>
								<div class="list">
									<div v-for="room in floor.floorroom" :key="room.concatcode"
										:style="{  'backgroundColor': getCurrentColor(room.house_type) }"
										@click="gotoDetail(room.concatcode)">
										<p>{{ room.roomnum }}（{{ room.roompersonnum }}人）</p>
										<p>户籍人口<span>{{ room.hujirenshu }}</span>人 <span>{{ room.hujishu }}</span>户</p>
										<p>流动人口<span>{{ room.liudongrenshu }}</span>人 <span>{{ room.liudonghushu }}</span>户</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<fangwu-person :houseCode="viewHouseCode" @setCode="viewHouseCode=''"></fangwu-person>
	</div>
</template>

<script>
	import fangwuPerson from "./fangwuGuiPersonInfo"
	// import vueSeamlessScroll from "vue-seamless-scroll";
	import {
		searchHouseTree,
		houseSheQuShow,
		houseXiaoQuShow,
		buildHouseNum,
		buildFloor
	} from '@/api/shaicha.js'
	import male from '../../assets/imgs/daping/male.png'
	import female from '../../assets/imgs/daping/female.png'
	import malelogo from '../../assets/imgs/daping/malelogo.png'
	import femalelogo from '../../assets/imgs/daping/femalelogo.png'
	export default {
		name: "fangwugui",
		components: {
			// vueSeamlessScroll,
			fangwuPerson
		},
		props: {},
		data() {
			return {
				fenleilist: [{
						color: '#ee3f38',
						name: '未采集'
					},
					{
						color: '#41a3ff',
						name: '常住'
					},
					{
						color: '#30b67d',
						name: '出租'
					},
					{
						color: '#40bfd2',
						name: '群租'
					},
					{
						color: '#fd751b',
						name: '空置'
					},
					{
						color: '#fca000',
						name: '门面房'
					},
					{
						color: '#d56c43',
						name: '地下室'
					}
				],
				data: [],
				defaultProps: {
					children: 'regionlist',
					label: 'label'
				},
				sexRatioChartDom: null,
				personinfo: [],
				personAge: [],
				concatcode: null,
				houseTypeChartDom: null,
				housetypelist: [],
				rankFiveChartDom: null,
				rankFenbu: [],
				zoufangList: [],
				defaultOption: {
					step: 0.4
				},
				visualAngle: 'shequ',
				xiaoquName: '',
				buildName: '',
				buildCode: '',
				xiaoqunums: [],
				xiaoqunumsSum: 0,
				xiaoquHouseSum: 0,
				xiaoquLiveNum: 0,
				xiaoquOtherNum: 0,
				personfenbu: {},
				expandedKeys: [],
				roomnum: 0,
				moveInOutDom: null,
				qianrulist: [],
				qianchulist: [],
				buildlist: [],
				totalUnitNums: 0,
				businessFlag: false,
				gridlist: [],
				gridlistNum: 0,
				gridmanlist: [],
				housenumlist: [],
				houseFloorList: [],
				loading: false,
				viewHouseCode: null,
				curIndex: null,
				moveInDom: null,
				moveOutDom: null,
				moveInList: [],
				moveOutList: [],
				regionType: 2,
				loudongnum:'',
			}
		},
		watch: {},
		created() {},
		mounted() {
			this.getTreeData();
		},
		computed: {},
		methods: {

			getTreeData() {
				//进入页面，默认加载社区数据
				this.visualAngle = 'shequ';
				searchHouseTree({
					userid: this.GLOBAL.adminId(),
				}).then(res => {
					if (res.result == 200) {
						let tree = res.detail.tree;
						tree.map(item => {
							item.label = item.name;
							item.regioncode = item.levelcode;
							item.regionlist.map(region => {
								region.label = region.regionname;
							})
						})
						this.data = tree;
						this.concatcode = tree[0].levelcode;
						this.expandedKeys = [tree[0].regioncode];
						this.getData();
					} else {
						this.$message({
							message: res.description,
							type: "warning",
						});
					}
				})
			},
			//获取社区数据
			getData() {
				this.loading = true;
				houseSheQuShow({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId(),
					concatcode: this.concatcode
				}).then((res) => {
					this.loading = false;
					if (res.result == '200') {
						//性别比例
						this.personinfo = res.detail.personinfo;
						this.personAge = res.detail.agelist;
						this.sexRatioChart();
						//人口分布
						this.personfenbu = res.detail.personfenbu;
						// //小区流动人口前五排名
						// this.rankFenbu = res.detail.zukefenbu.fenbu;
						// if (this.rankFenbu && this.rankFenbu.length) {
						// 	let timer = setTimeout(() => {
						// 		this.rankfiveChart();
						// 		clearTimeout(timer)
						// 	}, 100)
						// }

						// //走访记录
						this.zoufangList = res.detail.appmanlist;
						//房屋类型数
						let houseTypeList = res.detail.housetype;
						houseTypeList.map(item => {
							if (item.name == '未采集') {
								item.color = '#dc5853'
							} else if (item.name == '出租') {
								item.color = '#55b48c'
							} else if (item.name == '群组') {
								item.color = '#57bdcc'
							} else if (item.name == '空置') {
								item.color = '#e09434'
							}
						})
						this.housetypelist = houseTypeList;
						if (this.housetypelist && this.housetypelist.length) {
							let timer = setTimeout(() => {
								this.houseTypeChart();
								clearTimeout(timer)
							}, 100)
						}

						this.xiaoquLiveNum = 0;
						this.xiaoquOtherNum = 0;
						this.housetypelist.map(item => {
							if (item.name == '门面房' || item.name == '地下室') {
								this.xiaoquOtherNum += item.value;
							} else {
								this.xiaoquLiveNum += item.value;
							}
						})
						//房屋情况
						this.xiaoqunumsSum = 0;
						this.xiaoquHouseSum = 0;
						this.xiaoqunums = res.detail.xiaoqunums;
						this.xiaoqunums.map(item => {
							this.xiaoqunumsSum += item.value;
							this.xiaoquHouseSum += item.housenums;
						})
						// 网格信息
						// 网格员信息
						this.gridlist = res.detail.gridlist;

						this.gridmanlist = res.detail.gridmanlist;
						for (let item of this.gridmanlist) {
							if (!item.photopath) {
								item.photopath = item.sex == '男' ? male : female;
							}
							item.sexlogo = item.sex == '男' ? malelogo : femalelogo;
						}
						this.gridlistNum = res.detail.gridlistnum;

						//迁入、迁出信息
						let qianruData = res.detail.qianrulist;
						this.moveInOrOutChart('moveIn', qianruData, '迁入信息');
						let qianchuData = res.detail.qianchulist;
						this.moveInOrOutChart('moveOut', qianchuData, '迁出信息');

					} else {
						this.$message.error(res.description);
					}
				})
			},
			//获取小区数据
			getXiaoquData() {
				this.loading = true;
				houseXiaoQuShow({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId(),
					concatcode: this.concatcode
				}).then((res) => {
					this.loading = false;
					if (res.result == '200') {
						// console.log(res)
						//性别比例
						this.personinfo = res.detail.personinfo;
						this.personAge = res.detail.agelist;
						this.sexRatioChart();
						//人口分布
						this.personfenbu = res.detail.personfenbu;
						//走访记录
						this.zoufangList = res.detail.appmanlist;
						//房屋类型数
						this.housetypelist = res.detail.housetype;
						if (this.housetypelist && this.housetypelist.length) {
							let timer = setTimeout(() => {
								this.houseTypeChart();
								clearTimeout(timer)
							}, 100)
						}

						this.xiaoquLiveNum = 0;
						this.xiaoquOtherNum = 0;
						this.housetypelist.map(item => {
							if (item.name == '门面房' || item.name == '地下室') {
								this.xiaoquOtherNum += item.value;
							} else {
								this.xiaoquLiveNum += item.value;
							}
						})
						// 迁入迁出
						this.qianrulist = res.detail.qianrulist;
						this.qianchulist = res.detail.qianchulist;
						// this.moveInOutChart();
						// 小区多少栋
						// 小区所属网格
						// 小区负责人
						let buildlist = [];
						let loudongnum = 0;
						this.totalUnitNums = 0;
						for(let item of res.detail.buildlist){
							buildlist.push({
								regionname:item[0].regionname,
								businessFlag:false,
								detail:item
							})
						}
						for(let item of buildlist){
							loudongnum = loudongnum + item.detail.length;
							for(let item2 of item.detail){
								this.totalUnitNums += item2.mapinfo.unitnums;
								if (item2.shangyeflag == 1) {
									item.businessFlag = true;
								}
							}
						}
						this.loudongnum = loudongnum;
						this.buildlist = buildlist;
						// this.buildlist = res.detail.buildlist;
						// this.totalUnitNums = 0;
						// this.businessFlag = false;
						// this.buildlist.map(item => {
						// 	this.totalUnitNums += item.mapinfo.unitnums;
						// 	if (item.shangyeflag == 1) {
						// 		this.businessFlag = true;
						// 	}
						// })
						let idlist = this.concatcode.split(',');
						let gridlist = [];
						for(let item of idlist){
							gridlist.push({
								regioncode:item,
								regionname:'',
								gridtotal:[]
							})
						}
						for(let item of res.detail.gridlist){
							for(let item2 of gridlist){
								if(item.regioncode == item2.regioncode){
									item2.regionname = item.regionname;
									item2.gridtotal.push(item.grid_name.split('区')[1].split('网')[0])
								}
							}
						}
						for(let item of gridlist){
							item.gridtotal = item.gridtotal.join('、');
						}
						
						this.gridlist = gridlist;
						this.gridlistNum = res.detail.gridlistnum;
						this.gridmanlist = res.detail.gridmanlist;
						for (let item of this.gridmanlist) {
							if (!item.photopath) {
								item.photopath = item.sex == '男' ? male : female;
							}
							item.sexlogo = item.sex == '男' ? malelogo : femalelogo;
						}
					} else {
						this.$message.error(res.description);
					}
				})
			},
			getCurrentColor(text) {
				let textColor = "";
				this.fenleilist.map(item => {
					if (text == item.name) {
						textColor = item.color
					}
				})
				return textColor
			},
			//获取栋详情
			getBuildData(id, name) {
				this.loading = true;
				this.visualAngle = 'dong';
				this.buildName = name;
				this.buildCode = id;
				this.curIndex = 0;

				buildHouseNum({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId(),
					concatcode: id
				}).then((res) => {
					this.loading = false;
					if (res.result == '200') {
						//性别比例
						this.personinfo = res.detail.personinfo;
						this.personAge = res.detail.agelist;
						this.sexRatioChart();
						//人口分布
						this.personfenbu = res.detail.personfenbu;
						//走访记录
						this.zoufangList = res.detail.appmanlist;
						//房屋类型数
						this.housetypelist = res.detail.housetype;
						if (this.housetypelist && this.housetypelist.length) {
							let timer = setTimeout(() => {
								this.houseTypeChart();
								clearTimeout(timer)
							}, 100)
						}
						this.xiaoquLiveNum = 0;
						this.xiaoquOtherNum = 0;
						this.housetypelist.map(item => {
							if (item.name == '门面房' || item.name == '地下室') {
								this.xiaoquOtherNum += item.value;
							} else {
								this.xiaoquLiveNum += item.value;
							}
						})

						// 迁入迁出
						this.qianrulist = res.detail.qianrulist;
						this.qianchulist = res.detail.qianchulist;
						// this.moveInOutChart();
						// 单元数
						this.housenumlist = res.detail.houseNumList;
						this.housetypelist.map(item => {
							if (item.name == '未采集') {
								item.color = '#dc5853'
							} else if (item.name == '出租') {
								item.color = '#55b48c'
							} else if (item.name == '群租') {
								item.color = '#57bdcc'
							} else if (item.name == '空置') {
								item.color = '#e09434'
							} else if (item.name == '门面房') {
								item.color = '#999'
							} else if (item.name == '地下室') {
								item.color = '#800080'
							}
						})
						// 默认获取第1单元信息
						let firstUnitNum = this.housenumlist[this.curIndex].housenum;
						this.getFloorData(firstUnitNum)

						if (this.housenumlist.length == 1) { //没有单元数据
							if (!this.housenumlist[0].housenum) {
								this.housenumlist = []
							}
						}
						console.log('单元', this.housenumlist);
						// 小区负责人
						this.gridmanlist = res.detail.gridmanlist;
						for (let item of this.gridmanlist) {
							if (!item.photopath) {
								item.photopath = item.sex == '男' ? male : female;
							}
							item.sexlogo = item.sex == '男' ? malelogo : femalelogo;
						}
					} else {
						this.$message.error(res.description);
					}
				})
			},
			//获取单元数据
			getFloorData(num, index) {
				this.loading = true;
				this.visualAngle = 'room';
				if (index !== undefined) {
					this.curIndex = index;
				}
				buildFloor({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId(),
					concatcode: this.buildCode,
					housenum: num ? num : null
				}).then((res) => {
					this.loading = false;
					if (res.result == '200') {
						this.houseFloorList = res.detail.houseFloorList;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			gotoDetail(code) {
				this.viewHouseCode = code;
			},
			handleCheckChange() {
				console.log(this.$refs.tree.getCheckedKeys())
				console.log(this.expandedKeys)
				let checkedKeys = this.$refs.tree.getCheckedKeys();
				if (checkedKeys.length == 0) {
					return false;
				}
				//说明选择了根节点，那就只传tree的根节点id
				if (checkedKeys.indexOf(this.expandedKeys[0]) !== -1) {
					console.log(checkedKeys.indexOf(this.expandedKeys[0]))
					this.visualAngle = 'shequ';
					this.concatcode = this.data[0].levelcode; //通过id到tree的数据里面去找concatcode
					this.getData();
				} else {
					console.log(checkedKeys)
					console.log(this.data)
					let regionlist = this.data[0].regionlist; //通过id到tree的数据里面去找concatcode
					let regionlistIds = [];
					let regionlistNames = [];
					checkedKeys.map(regioncode => {
						regionlist.map(item => {
							if (regioncode == item.regioncode) {
								regionlistIds.push(item.regioncode);
								regionlistNames.push(item.regionname);
							}
						})
					})
					this.visualAngle = 'xiaoqu';
					this.concatcode = regionlistIds.join(',')
					this.xiaoquName = regionlistNames.join('，')
					this.getXiaoquData();
				}

			},
			handleNodeClick(data, node) {
				console.log(data);
				console.log(node)
				this.regionType = data.type;
				if (node.level == 1) {
					this.visualAngle = 'shequ';
					this.concatcode = data.levelcode;
					this.getData();
				} else if (node.level == 2) {
					this.visualAngle = 'xiaoqu';
					this.concatcode = data.regioncode;
					this.xiaoquName = data.regionname;
					this.getXiaoquData();
				}

			},
			//迁入或迁出图表
			moveInOrOutChart(domId, data, name) {
				if (
					this[domId + 'Dom'] != null &&
					this[domId + 'Dom'] != "" &&
					this[domId + 'Dom'] != undefined
				) {
					this[domId + 'Dom'].dispose();
				}

				this[domId + 'Dom'] = this.$echarts.init(
					document.getElementById(domId)
				);

				let seriesName = name;
				let nums = [];
				let dates = [];

				data.map(item => {
					nums.push(item.value);
					dates.push(item.name)
				})

				let option = {
					title: {
						text: '',
						textStyle: {
							color: '#333',
							fontSize: 14
						},
						subtext: '单位：次',
						subtextStyle: {
							fontSize: 12,
							color: '#464646'
						}
					},
					grid: {
						top: '20%',
						left: '20',
						right: '20',
						bottom: '20',
						containLabel: true
					},
					tooltip: {
						trigger: 'axis'
					},
					color: [seriesName == '迁出信息' ? '#eb5353' : '#59a9fc'],
					xAxis: {
						type: 'category',
						data: dates,
						axisLabel: {
							color: '#464646'
						},
						axisTick: {
							show: false
						}
					},
					yAxis: {
						type: 'value',
						// interval: 1
					},
					series: [{
						name: seriesName,
						data: nums,
						type: 'line',
						areaStyle: {
							color: new this.$echarts.graphic.LinearGradient(
								0, 0, 0, 1,
								[{
										offset: 0,
										color: seriesName == '迁出信息' ? '#eb5353' : '#59a9fc'
									},
									{
										offset: 1,
										color: seriesName == '迁出信息' ? 'rgba(235, 83, 83, 0.1)' :
											'rgba(89, 169 , 252, 0.1)'
									}
								]
							)
						},
						showSymbol: false,
						smooth: true,
						lineStyle: {
							color: seriesName == '迁出信息' ? '#eb5353' : '#59a9fc'
						},
					}]
				};
				option && this[domId + 'Dom'].setOption(option);
			},

			//迁入迁出图表
			moveInOutChart() {
				if (
					this.moveInOutDom != null &&
					this.moveInOutDom != "" &&
					this.moveInOutDom != undefined
				) {
					this.moveInOutDom.dispose();
				}

				this.moveInOutDom = this.$echarts.init(
					document.getElementById("moveInOut")
				);

				let moveInNums = [];
				let moveOutNums = [];
				let dates = [];

				this.qianrulist.map(item => {
					moveInNums.push(item.value);
					dates.push(item.name)
				})
				this.qianchulist.map(item => {
					moveOutNums.push(item.value)
				})


				let option = {
					title: {
						subtext: '单位：次',
						subtextStyle: {
							fontSize: 12,
							color: '#464646'
						}
					},
					grid: {
						top: '20%',
						left: '20',
						right: '20',
						bottom: '20',
						containLabel: true
					},
					tooltip: {
						trigger: 'axis'
					},
					color: ['#59a9fc', '#eb5353'],
					xAxis: {
						type: 'category',
						// boundaryGap: false,
						data: dates,
						axisLabel: {
							color: '#464646'
						},
						axisTick: {
							show: false
						}
					},
					yAxis: {
						type: 'value'
					},
					series: [{
							name: '迁入数量',
							data: moveInNums,
							type: 'line',
							areaStyle: {
								color: new this.$echarts.graphic.LinearGradient(
									0, 0, 0, 1,
									[{
											offset: 0,
											color: '#59a9fc'
										},
										{
											offset: 1,
											color: 'rgba(89, 169 , 252, 0)'
										}
									]
								)
							},
							showSymbol: false,
							// 	smooth: true,
						},
						{
							name: '迁出数量',
							data: moveOutNums,
							type: 'line',
							lineStyle: {
								color: '#eb5353'
							},
							showSymbol: false
						}
					]
				};
				option && this.moveInOutDom.setOption(option);
			},

			//小区流动人口前五排名
			rankfiveChart() {
				if (
					this.rankFiveChartDom != null &&
					this.rankFiveChartDom != "" &&
					this.rankFiveChartDom != undefined
				) {
					this.rankFiveChartDom.dispose();
				}

				this.rankFiveChartDom = this.$echarts.init(
					document.getElementById("rankfiveChart")
				);

				let xAxisData = [];
				let yAxisData = [];
				this.rankFenbu.map((item, index) => {
					xAxisData.push(item.regionname);
					yAxisData.push(item.nums)
				})

				let option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					color: ['#53c9fe'],
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: [{
						type: 'value',
						axisLabel: {
							show: false
						},
						splitLine: {
							show: false
						}
					}],
					yAxis: [{
						type: 'category',
						data: xAxisData,
						axisTick: {
							show: false
						},
						axisLine: {
							show: false
						},
						axisLabel: {

						},
						inverse: true
					}],
					series: [{
						name: '',
						type: 'bar',
						barWidth: '20',
						data: yAxisData,
						itemStyle: {
							normal: {
								borderRadius: [10]
							}
						}
					}]
				};
				option && this.rankFiveChartDom.setOption(option);
			},


			//房屋类型数
			houseTypeChart() {

				if (
					this.houseTypeChartDom != null &&
					this.houseTypeChartDom != "" &&
					this.houseTypeChartDom != undefined
				) {
					this.houseTypeChartDom.dispose();
				}

				this.houseTypeChartDom = this.$echarts.init(
					document.getElementById("houseTypeChart")
				);

				let xAxisData = [];
				let yAxisData = [];
				this.roomnum = 0; //房屋信息
				this.housetypelist.map(item => {
					xAxisData.push(item.name);
					yAxisData.push(item.value)
					this.roomnum += item.value;
				})

				let option = {
					title: [{
						subtext: '单位：户',
						subtextStyle: {
							fontSize: 12,
							color: '#464646'
						}
					}],
					color: ['#46a8ed'],
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						top: '18%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: xAxisData,
						axisTick: {
							show: false
						}
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
						// name: '房屋类型数',
						type: 'bar',
						barWidth: '40%',
						data: yAxisData,
						label: {
							show: true,
							position: 'top'
						}
					}]
				};
				option && this.houseTypeChartDom.setOption(option);
			},

			//性别比例
			sexRatioChart() {
				if (
					this.sexRatioChartDom != null &&
					this.sexRatioChartDom != "" &&
					this.sexRatioChartDom != undefined
				) {
					this.sexRatioChartDom.dispose();
				}

				this.sexRatioChartDom = this.$echarts.init(
					document.getElementById("buildSexRatioChart")
				);

				let option = {
					tooltip: {
						trigger: "item",
						formatter: "{a} <br/>{b}: {c} ({d}%)",
					},
					legend: {
						orient: "vertical",
						right: "10%",
						top: "center",
						// data: legendArr,
						textStyle: {
							color: "#383838",
						},
					},
					series: [{
							name: "性别比例",
							type: "pie",
							left: "-40%",
							radius: [0, "40%"],
							label: {
								position: "inner",
								fontSize: 14,
								textStyle: {
									color: "#fff",
								},
							},
							labelLine: {
								show: false,
							},
							data: this.personinfo,
							color: ["#f97c44", "#3b85e1"],
						},
						{
							name: "年龄比例",
							type: "pie",
							left: "-40%",
							radius: ["60%", "75%"],
							label: {
								show: false,
							},
							labelLine: {
								show: false,
							},
							data: this.personAge,
							color: [
								"#00d7e9",
								"#70cbf0",
								"#f6bd15",
								"#5b8ff9",
								"#1a9c69",
								"#706dd3",
							],
						},
					],
				};
				option && this.sexRatioChartDom.setOption(option);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.guibox {
		box-sizing: border-box;
		height: 100%;
		overflow: auto;
		min-width: 1100px;
		color: #333;
		background-color: #f5f6f6;
		$guiLeftW: 260px;

		.guileft {
			float: left;
			height: 100%;
			overflow: auto;
			width: $guiLeftW;
			box-sizing: border-box;
			background-color: #fff;
		}

		.guiright {
			float: right;
			width: calc(100% - #{$guiLeftW} - 15px);
			height: 100%;
			overflow: auto;
		}

		.gridmanlist {
			font-size: 14px;
			color: #666;
			display: flex;
			align-items: center;

			img {
				width: 60px;
				height: auto;
				margin-right: 10px;
			}
		}
	}

	.centerinfo {
		display: flex;
		justify-content: space-between;

		.centerleft {
			width: 33%;

			.box {
				padding: 14px 8px 8px;
				box-sizing: border-box;
				background-color: #fff;
			}

			.box1 {
				margin-bottom: 14px;

				.content {
					height: 182px;
				}
			}

			.box2 {
				margin-bottom: 14px;

				.content {
					height: 196px;
				}
			}

			.box3 {
				.content {
					text-align: center;

					.kuang {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						box-sizing: border-box;
						padding: 0 5px;
					}

					.one {
						width: 21%;
					}

					.two {
						width: 32%;
					}

					.three {
						flex: 1;
					}

					.toubu {
						line-height: 28px;
						display: flex;
						font-size: 16px;
						color: #505778;
					}

					.neirong {
						height: 158px;
						overflow-y: auto;

						.item {
							display: flex;
							line-height: 28px;
							font-size: 14px;
							color: #676767;
						}

						.item:nth-of-type(2n-1) {
							background-color: #f0f2fc;
						}
					}
				}
			}
		}

		.centerright {
			width: calc(67% - 14px);

			.box1 {
				margin-bottom: 14px;
				padding: 14px 8px 8px;
				box-sizing: border-box;
				background-color: #fff;

				.content {
					height: 182px;
					display: flex;
					justify-content: space-around;
					align-items: center;

					.item {
						width: 288px;
						height: 102px;
						box-sizing: border-box;
						padding-left: 88px;
						border-radius: 4px;
						background-size: 43px 43px;
						background-repeat: no-repeat;
						background-position: 29px center;
						line-height: 102px;
						color: #686868;
						font-size: 16px;

						span {
							font-size: 20px;
							color: #282828;
						}
					}

					.item1 {
						background-color: #f2f6ff;
						background-image: url(../../assets/imgs/daping/wangge.png);
					}

					.item2 {
						background-color: #fcf8ed;
						background-image: url(../../assets/imgs/daping/wanggeyuan.png);
					}
				}
			}

			.box2 {
				background-color: #fff;
				box-sizing: border-box;
				padding: 10px 8px;

				.qianru {
					height: 215px;
				}

				.qianchu {
					height: 215px;
				}
			}

			.box3 {
				margin-bottom: 14px;
				padding: 14px 8px 8px;
				box-sizing: border-box;
				background-color: #fff;

				.content {

					display: flex;

					.zuobian {
						height: 182px;
						display: flex;
						flex-direction: column;
						justify-content: space-around;
						box-sizing: border-box;
						width: 35%;
						padding-right: 20px;

						div {
							height: 80px;
							line-height: 80px;
							box-sizing: border-box;
							padding-left: 100px;
							border-radius: 8px;
							background-color: #f0f2fc;
							background-size: 52px 49px;
							background-repeat: no-repeat;
							background-position: 29px center;
							font-size: 16px;
							color: #686868;

							span {
								color: #282828;
								font-size: 20px;
								font-weight: 700;
							}
						}

						.item1 {
							background-image: url(../../assets/imgs/daping/loudongbig.png);
						}

						.item2 {
							background-image: url(../../assets/imgs/daping/loudongsmall.png);
						}
					}

					.youbian {
						display: flex;
						flex: 1;
						height: 182px;
						overflow-y: auto;

						.item {
							height: 33px;
							line-height: 33px;
							border-top: 1px solid #d9d9d9;
							font-size: 16px;
							color: #686868;

							span {
								color: #282828;
								font-weight: 700;
							}
						}

						.item:first-child {
							border-top: none !important;
						}

						.xiaoqulist {
							.item {
								padding: 0 10px 0 36px;
								background-size: 19px 19px;
								background-position: 9px center;
								background-repeat: no-repeat;
								background-image: url(../../assets/imgs/daping/danyuan.png);
							}
						}

						.wanggelist {
							flex: 1;
						}
					}

				}
			}

			.box4 {
				background-color: #fff;
				box-sizing: border-box;
				padding: 10px 8px;
				height: 490px;
				overflow-y: auto;

				.bigtitle {
					overflow: hidden;

					.xiaoquname {
						float: left;
						height: 40px;
						line-height: 40px;
						color: #282828;
						font-weight: 700;
						padding-left: 13px;
						font-size: 16px;
						background-size: 8px 11px;
						background-repeat: no-repeat;
						background-position: left center;
						background-image: url(../../assets/imgs/daping/jiantou.png);
					}

					.shangpin {
						font-size: 14px;
						float: right;
						color: #17cb9a;
						height: 40px;
						line-height: 40px;

						div {
							float: left;
							border-radius: 4px;
							width: 14px;
							height: 14px;
							margin: 13px 6px 0 0;
							background-color: #17cb9a;
						}

						p {
							float: left;
						}
					}
				}

				.danyuanlist {
					display: flex;
					flex-wrap: wrap;

					.item {
						margin: 0 10px 10px 0;
						cursor: pointer;

						.mingcheng {
							width: 154px;
							position: relative;

							p {
								height: 27px;
								line-height: 27px;
								text-align: center;
								color: #fff;
								font-size: 16px;
								position: relative;
								z-index: 1;
							}

							.beijing {
								border-bottom: 27px solid #378ef8;
								border-left: 10px solid transparent;
								border-right: 10px solid transparent;
								height: 0;
								width: 154px;
								box-sizing: border-box;
								position: absolute;
								left: 0;
								top: 0;
							}
						}

						.detail {
							box-sizing: border-box;
							padding: 8px;
							width: 154px;
							background-color: #dfeeff;

							div {
								display: flex;
								justify-content: space-between;
								box-sizing: border-box;
								font-size: 14px;
								line-height: 22px;
								padding: 0 10px;
								color: #4d4d4d;

							}

							.teshu {
								color: #fff;
								background-color: #ff9f22;
								border-radius: 100px;
							}

							.teshu2 {
								color: #fff;
								background-color: #f6bd15;
								border-radius: 100px;
							}
						}
					}

					.shangpin {
						.mingcheng {
							.beijing {
								border-bottom: 27px solid #17cb9a;
							}
						}

						.detail {
							background-color: #deffef;
						}
					}
				}
			}

			.box5 {
				margin-bottom: 14px;
				padding: 14px 8px 8px;
				box-sizing: border-box;
				background-color: #fff;

				.content {
					height: 182px;

					.louname {
						display: flex;
						justify-content: center;
						padding: 34px 0;

						p {
							padding-left: 70px;
							line-height: 49px;
							background-size: 52px 49px;
							background-repeat: no-repeat;
							background-position: left top;
							background-image: url(../../assets/imgs/daping/loudongbig.png);
							font-size: 20px;
							font-weight: 700;
							color: #282828;
						}
					}

					.danyuanlist {
						display: flex;
						justify-content: space-around;
						align-items: center;

						.total {
							line-height: 49px;
							padding-left: 60px;
							background-size: 52px 49px;
							background-repeat: no-repeat;
							background-position: left top;
							background-image: url(../../assets/imgs/daping/loudongsmall.png);
							font-size: 16px;
							color: #686868;

							span {
								font-size: 20px;
								font-weight: 700;
								color: #282828;
							}
						}

						.item {
							padding-left: 27px;
							line-height: 25px;
							cursor: pointer;
							background-size: 19px 19px;
							background-repeat: no-repeat;
							background-position: left center;
							background-image: url(../../assets/imgs/daping/danyuan.png);
							font-size: 16px;
							color: #282828;
							font-weight: 700;
						}

						.item:hover {
							color: #1f92e2;
						}

						.selected {
							color: #1f92e2;
						}
					}
				}
			}

			.box6 {
				background-color: #fff;
				box-sizing: border-box;
				padding: 10px 8px;

				.fenleilist {
					display: flex;

					.item {
						display: flex;
						align-items: center;
						line-height: 48px;
						color: #686868;
						margin-right: 20px;

						div {
							width: 14px;
							height: 14px;
							margin-right: 6px;
							border-radius: 3px;
						}
					}
				}

				.fangwulist {
					box-sizing: border-box;
					height: 422px;
					overflow-y: auto;

					.item {
						.floor {
							line-height: 20px;
							font-size: 16px;
							margin-bottom: 10px;
							color: #282828;
							font-weight: 700;
						}

						.list {
							display: flex;
							flex-wrap: wrap;

							div {
								line-height: 24px;
								font-size: 16px;
								color: #fff;
								padding: 5px 15px;
								margin: 0 10px 10px 0;
								border-radius: 4px;
								font-weight: 700;
								cursor: pointer;
							}
						}
					}
				}
			}
		}
	}

	.commontitle {
		height: 20px;
		line-height: 20px;
		padding-left: 8px;
		box-sizing: border-box;
		border-left: 3px solid #3c8ef4;
		font-size: 18px;
		color: #303030;
		font-weight: 700;
	}

	.topinfo {
		display: flex;
		justify-content: space-between;
		margin-bottom: 14px;
		width: 100%;

		.fangwuinfo {
			height: 184px;
			background-color: #fff;
			box-sizing: border-box;
			padding: 20px 10px 0;

			.box {
				display: flex;
				justify-content: space-between;

				.item {
					display: flex;
					justify-content: center;
					flex-direction: column;
					height: 114px;
					border-radius: 8px;
					box-sizing: border-box;
					padding-left: 83px;
					background-repeat: no-repeat;
					background-size: 62px 62px;
					background-position: 12px center;
					color: #fff;
					box-sizing: border-box;
					padding-right: 12px;

					.title {
						width: 100%;
						line-height: 20px;
						font-size: 16px;
					}

					.num {
						line-height: 32px;
						font-size: 18px;

						span {
							font-size: 22px;
						}
					}

					.detail {
						display: flex;
						flex-direction: row;
						line-height: 32px;
						border-top: 1px solid #fff;
						font-size: 14px;
					}
				}
			}
		}

		.fangwuinfo2 {
			width: 63%;
			display: flex;

			.box {
				.item1 {
					background-image: url(../../assets/imgs/daping/fangwu.png);
					background-color: #ff7926;
				}
			}

			.gridbox {
				flex: 1;
				box-sizing: border-box;
				padding-left: 30px;

				.gridtitle {
					height: 20px;
					line-height: 20px;
					box-sizing: border-box;
					font-size: 16px;
					color: #303030;
					font-weight: 700;
					margin-bottom: 3px;
					padding-left: 8px;
				}

				.gridlist {
					overflow-y: auto;
					display: flex;
					flex-wrap: wrap;
					height: 128px;

					.gridman {
						display: flex;
						padding: 7px 8px;
						height: 48px;

						img {
							display: block;
							width: 48px;
							height: 48px;
							margin-right: 9px;
						}

						div {
							display: flex;
							flex-direction: column;
							justify-content: center;

							p {
								line-height: 24px;
							}

							.name {
								font-size: 16px;
								color: #282828;
								font-weight: 700;
								background-size: 16px 16px;
								background-position: right center;
								background-repeat: no-repeat;
							}

							.phone {
								color: #686868;
								font-size: 14px;
							}
						}
					}
				}
			}
		}

		.renkouinfo1 {
			width: calc(42% - 14px);
		}

		.renkouinfo2 {
			width: calc(37% - 14px);
		}

		.fangwuinfo1 {
			width: 58%;

			.box {
				.item1 {
					background-image: url(../../assets/imgs/daping/fangwu.png);
					background-color: #ff7926;

				}

				.item2 {
					background-image: url(../../assets/imgs/daping/nongcunzu.png);
					background-color: #529bf1;
				}

				.item3 {
					background-image: url(../../assets/imgs/daping/xiaoqu.png);
					background-color: #52ca8e;
				}
			}
		}

		.renkouinfo {
			height: 184px;
			background-color: #fff;
			box-sizing: border-box;
			padding: 20px 10px 0;

			.box {
				display: flex;
				justify-content: space-around;

				.item1 {
					padding-top: 26px;

					p {
						line-height: 20px;
						font-size: 16px;
						color: #686868;

						.name {
							color: #35cca7;
						}

						.num {
							color: #282828;
							font-size: 20px;
						}
					}

					.fenge {
						margin: 8px 0;
						width: 100%;
						height: 2px;
						background-color: #35cca7;
					}
				}

				.item2 {
					.biao {
						line-height: 26px;
						font-size: 16px;
						color: #282828;
					}

					p {
						line-height: 20px;
						font-size: 16px;
						color: #686868;

						.name {
							color: #48a5fa;
						}

						.num {
							color: #282828;
							font-size: 20px;
						}
					}

					.fenge {
						margin: 8px 0;
						width: 100%;
						height: 2px;
						background-color: #48a5fa;
					}
				}

				.item3 {
					p:first-child {
						border-top: none;
					}

					p {
						border-top: 1px solid #dcdcdc;
						line-height: 30px;
						font-size: 16px;
						color: #686868;

						span {
							color: #282828;
						}
					}
				}
			}
		}
	}
</style>
<template>
	<div>
		<el-dialog title="同住人员信息" width='90%' :visible.sync="xiangqingHouseFlag" :before-close="handleCloseHouse"
			:close-on-click-modal="false">

			<div class="create" style="overflow: auto;">
				<div v-loading="loadingHouse" v-if="housemsg" class="detailtable">
					<p>房屋信息</p>
					<div class="labelname">住址</div>
					<div style="width: 26%;">{{housemsg.housename}}</div>
					<div class="labelname">所在社区</div>
					<div style="width: 14%;">{{housemsg.name}}</div>
					<div class="labelname">房屋性质</div>
					<div style="width: 14%;">{{housemsg.house_type}}</div>
					<div class="labelname">住房种类</div>
					<div style="width: 14%;">{{housemsg.house_type_flag}}</div>
					<div class="labelname">房屋面积</div>
					<div style="width: 26%;">{{housemsg.area}}平方米</div>
					<div class="labelname">机动车数量</div>
					<div style="width: 14%;">{{housemsg.carnum}}</div>
					<div class="labelname">电动车数量</div>
					<div style="width: 14%;">{{housemsg.bikenum}}</div>
					<div class="labelname">宠物类型</div>
					<div style="width: 14%;">{{housemsg.pettype}}</div>
				</div>
				<div v-loading="loadingHouse" v-else class="detailtable">
					<p>房屋信息</p>
					<div style="width: 100%; text-align: left;">暂无</div>
				</div>

				<div style="padding: 20px; display: flex; justify-content: flex-end;">
					<el-radio-group v-model="showType">
						<el-radio :label="1">户型展示</el-radio>
						<el-radio :label="2">表格展示</el-radio>
						<el-radio :label="3">关系图展示</el-radio>
					</el-radio-group>
				</div>

				<div class="personsbox" v-if="showType == 3">
					<div class="huzhuinfo">
						<div class="fz_item" v-if="masterlist[0]">
							<div class="head">
								<div class="iconsex">
									<img v-if="masterlist[0].person_sex=='女'" src="@/assets/imgs/woman.png" alt="女">
									<img v-else src="@/assets/imgs/man.png" alt="男">
								</div>
								<img src="@/assets/imgs/fangzhu.png" alt="房主">
							</div>
							<ul>
								<li><span>姓名：</span>{{ masterlist[0].mastername}}</li>
								<li><span>电话：</span>{{ masterlist[0].phone}}</li>
								<li><span>身份证号：</span>{{ masterlist[0].personcard }}</li>
							</ul>
						</div>
						<div class="fz_item" v-else>
							<div class="head">
								<i class="el-icon-user-solid"></i>
								<img src="@/assets/imgs/fangzhu.png" alt="房主">
							</div>
							<div>暂无房主信息</div>
						</div>
					</div>

					<div class="zhuhuinfo">
						<div class="zh_item" v-for="item in personlist" :key="item.personcard">
							<div class="head">
								<div class="iconsex">
									<img v-if="item.person_sex=='女'" src="@/assets/imgs/woman.png" alt="女">
									<img v-else src="@/assets/imgs/man.png" alt="男">
								</div>
								<p v-if="item.housemasterrelation == '租客'">流动人口</p>
								<p v-else>常住人口</p>
							</div>
							<ul>
								<li><span>姓名：</span>{{ item.personname}}</li>
								<li><span>电话：</span>{{ item.personphone}}</li>
								<li><span>身份证号：</span>{{ item.personcard }}</li>
								<li><span>与房主关系：</span> {{ item.housemasterrelation }}</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="tabletype" v-if="showType == 2">
					<div v-loading="loadingHouse" class="detailtable" v-for="(item,index) in masterlist" :key="index">
						<p>房主基本情况</p>
						<div class="labelname">姓名</div>
						<div style="width: 13.4%;">{{item.mastername}}</div>
						<div class="labelname">手机号</div>
						<div style="width: 34.8%;">{{item.phone}}</div>
						<div class="labelname">身份证号</div>
						<div style="width: 27.8%;">{{item.personcard}}</div>
						<div class="labelname">居住地址</div>
						<div style="width: 92%;" class="biaoshi">{{item.address}}</div>
					</div>
					<div v-loading="loadingHouse" class="detailtable" v-for="item in personlist" :key="item.personcard">
						<p>居民基本情况</p>
						<div class="labelname">姓名</div>
						<div style="width: 17%;">{{item.personname}}</div>
						<div class="labelname">民族</div>
						<div style="width: 17%;">{{item.personnation}}</div>
						<div class="labelname">户籍地址</div>
						<div style="width: 42%;">{{item.censusaddr}}</div>
						<div class="labelname">性别</div>
						<div style="width: 17%;">{{item.person_sex}}</div>
						<div class="labelname">学历</div>
						<div style="width: 17%;">{{item.eduid}}</div>

						<div class="labelname">身份证号</div>
						<div style="width: 17%;">{{item.personcard}}</div>
						<div class="labelname">出生日期</div>
						<div style="width: 17%;">{{item.personbirthday}}</div>
						<div class="labelname">婚姻状况</div>
						<div style="width: 17%;">{{item.marriage}}</div>
						<div class="labelname">宗教信仰</div>
						<div style="width: 17%;">{{item.faith}}</div>
						<div class="labelname">联系方式</div>
						<div style="width: 17%;">{{item.personphone}}</div>
						<div class="labelname">人员类别</div>
						<div style="width: 17%;">
							<span>{{ getTextOfVal(item.incommunity) }}</span>
						</div>
						<div style="width:100%;padding: 0 !important;border-right: none !important;border-bottom: none !important;"
							v-if="item.incommunity == 0">
							<div class="labelname">与房主关系</div>
							<div style="width: 17%;">{{item.housemasterrelation}}</div>
							<div class="labelname">户号</div>
							<div style="width: 17%;">{{item.accountcode}}</div>
							<div class="labelname">与户主关系</div>
							<div style="width: 17%;">{{item.accountrelation}}</div>
							<div class="labelname">是否有居住证</div>
							<div style="width: 17%;">{{item.temporarycard}}</div>
						</div>
						<div style="width:100%;padding: 0 !important;border-right: none !important;border-bottom: none !important;"
							v-if="item.incommunity == 1">
							<div class="labelname">与房主关系</div>
							<div style="width: 42%;">{{item.housemasterrelation}}</div>
							<div class="labelname">是否有居住证</div>
							<div style="width: 42%;">{{item.temporarycard}}</div>
						</div>
						<div style="width:100%;padding: 0 !important;border-right: none !important;border-bottom: none !important;"
							v-if="item.incommunity == 2">
							<div class="labelname">户号</div>
							<div style="width: 42%;">{{item.accountcode}}</div>
							<div class="labelname">与户主关系</div>
							<div style="width: 42%;">{{item.accountrelation}}</div>
						</div>
						<div class="biaoqian" style="width: 100%;" v-if="item.labellist.length > 0">
							<ul>
								<li v-for="blab in item.labellist" :key="blab.label_item_id">{{ blab.label_name}}</li>
							</ul>
						</div>
					</div>

				</div>
				<div class="tabletype" v-if="showType == 1">
					<div class="alldata">
						<div class="hang one">
							<div>全部{{showpicpage.personnums}}人</div>
							<div>
								户籍人口{{showpicpage.huzairenbujingchangzai + showpicpage.huzairenbuzai + showpicpage.huzairenzai}}人
								户数{{showpicpage.hushu}}户</div>
							<div>流动人口{{showpicpage.liudongrenkou}}人 户数{{showpicpage.liudonghushu}}户</div>
							<div v-if="housemsg.roomallnums && housemsg.roomallnums>0">总户数{{housemsg.roomallnums}}户
								空余数{{housemsg.roomallnums - showpicpage.liudonghushu}}户</div>
						</div>
						<div class="hang two">
							<div @click="xuanze(0)" :class="selectleixing === 0?'selected':''">
								人在户在{{showpicpage.huzairenzai}}人</div>
							<div @click="xuanze(3)" :class="selectleixing === 3?'selected':''">
								户在人不在{{showpicpage.huzairenbuzai}}人</div>
							<div @click="xuanze(1)" :class="selectleixing === 1?'selected':''">
								户在人不经常在{{showpicpage.huzairenbujingchangzai}}人</div>
							<div @click="xuanze(2)" :class="selectleixing === 2?'selected':''">
								流动人口{{showpicpage.liudongrenkou}}人</div>
						</div>
						<div class="hang three">
							<div v-for="(item,index) in showpicpage.housepersonlable"
								:class="selectTagName === item.label_name?'highblue':''" :key="index"
								@click="highTag(item.label_name)">{{item.label_name}} {{item.labelnums}}人</div>
						</div>
					</div>
					<div class="allpeople">
						<div class="yihu" v-for="(item,index) in showpicpage.personinfolist" :key="index">
							<div class="title"> <img src="../../assets/imgs/houseicon.png" alt="">
								<p>{{item.num}}</p>
							</div>
							<div class="people" v-for="(item2,index2) in item.list" :key="index+index2">
								<div class="head" @click="lookClick(item2.id)">
									<div :class="item2.incommunity === selectleixing?'img lanse':'img huise'">
										<img v-if="item2.person_sex == '男' && item2.age < 18"
											src="../../assets/imgs/person/son.png" alt="">
										<img v-if="item2.person_sex == '女' && item2.age < 18"
											src="../../assets/imgs/person/girl.png" alt="">
										<img v-if="item2.person_sex == '男' && item2.age >= 18 && item2.age < 60"
											src="../../assets/imgs/person/father.png" alt="">
										<img v-if="item2.person_sex == '女' && item2.age >= 18 && item2.age < 60"
											src="../../assets/imgs/person/mother.png" alt="">
										<img v-if="item2.person_sex == '男' && item2.age >= 60"
											src="../../assets/imgs/person/grandfather.png" alt="">
										<img v-if="item2.person_sex == '女' && item2.age >= 60"
											src="../../assets/imgs/person/grandmother.png" alt="">
									</div>
									<p>{{item2.personname}}</p>
									<p v-if="item2.age" style="font-size:12px; line-height: 1em;">（{{ item2.age }}岁）</p>
									<div class="tag" v-for="(item3,index3) in item2.lablelist"
										:key="index+index2+index3"
										:class="item3.label_name === selectTagName? 'blue': ''">{{item3.label_name}}
									</div>
								</div>

							</div>
						</div>

					</div>
				</div>

			</div>
		</el-dialog>
		<el-dialog title="查看详情" width='75%' :visible.sync="xiangqing" :close-on-click-modal="false"
			:before-close="handleClose">
			<div class="create" style="overflow: auto;">
				<p>基础信息</p>
				<div class="produce">
					<div>姓名：{{jichu.mulpersoninfp.personname}}</div>
					<div>性别：{{jichu.mulpersoninfp.person_sex}}</div>
					<div>身份证：{{jichu.mulpersoninfp.personcard}}</div>
					<div>现居地：{{jichu.mulpersoninfp.housearea}}</div>
					<div>户籍地：{{jichu.mulpersoninfp.censusaddr}}</div>
					<div>电话：{{jichu.mulpersoninfp.personphone}}</div>
					<div>在住情况：{{jichu.mulpersoninfp.deldesc}}</div>
					<div>社区名：{{jichu.mulpersoninfp.communityname}}</div>
					<div>小区名/组名：{{jichu.mulpersoninfp.regionname}}</div>
					<div>户口性质：{{ getTextOfVal(jichu.mulpersoninfp.incommunity) }}</div>
					<!-- <div>是否为党员：{{jichu.mulpersoninfp.party}}</div> -->
					<div class="biaoqian" v-if="jichu.labelinfo && jichu.labelinfo.length > 0">
						<ul>
							<li v-for="blab in jichu.labelinfo" :key="blab.label_item_id">{{ blab.label_name}}</li>
						</ul>
					</div>
				</div>
		
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import {
		houseinfolist,
		indexPeronRelation,
		personInfo
	} from '@/api/shaicha'
	export default {
		name: "fangwuGuiPersonInfo",
		components: {},
		props: {
			houseCode: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				xiangqing:false,
				loadingHouse: false,
				housemsg: {},
				masterlist: [],
				personlist: [],
				showType: 1,
				xiangqingHouseFlag: false,
				nopersondesc: '',
				showpicpage: {
					housepersonlable: [],
					hushu: '',
					huzairenbujingchangzai: '',
					huzairenbuzai: '',
					huzairenzai: '',
					liudongrenkou: '',
					personinfolist: [],
					personnums: '',
					liudonghushu: ''
				},
				selectleixing: '',
				selectTagName: '',
				jichu: {
					mulpersoninfp: {
						personname: '',
						person_sex: '',
						personcard: '',
						houseareatemp: '',
						censusaddr: '',
						personphone: '',
						deldesc: '',
						communityname: '',
						regionname: '',
						party: '',
						housearea:'',
					},
					labelinfo:[],
					personLabel: [{
						name: '',
						labellist: [{
							history: false,
							labelname: ''
						}]
					}]
				},
			}
		},
		watch: {
			houseCode: {
				handler(newVal, oldVal) {
					if (newVal) {
						this.lookHouseInMan();
						this.showType = 1;
					}
				},
				deep: true
			}
		},
		created() {},
		mounted() {},
		computed: {},
		methods: {
			lookClick(a) {
				var that = this;
				personInfo({
					'x-access-token': that.GLOBAL.token(),
					"userid": that.GLOBAL.adminId(),
					queryid: a,
				}).then((res) => {
					if (res.result == '200') {
						that.jichu = res.detail;
						that.xiangqing = true;
					} else {
						that.$message.error(res.description);
					}
				})
			
			},
			handleClose: function(done) {
				this.historyData = [];
				this.jichu = {
					mulpersoninfp: {
						personname: '',
						person_sex: '',
						personcard: '',
						houseareatemp: '',
						censusaddr: '',
						personphone: '',
						deldesc: '',
						communityname: '',
						regionname: '',
						party: ''
					},
					personLabel: [{
						name: '',
						labellist: [{
							history: false,
							labelname: ''
						}]
					}]
				};
				done();
			},
			highTag(tagname) {
				if (this.selectTagName == tagname) {
					this.selectTagName = '';
				} else {
					this.selectTagName = tagname;
				}

			},
			xuanze(a) {
				let that = this;
				if (that.selectleixing === '') {
					that.selectleixing = a;
				} else {
					that.selectleixing = '';
				}
			},
			handleCloseHouse: function(done) {
				this.xiangqingHouse = false;
				this.$emit('setCode')
				done();
			},
			//查看同住人员信息
			lookHouseInMan() {
				this.xiangqingHouseFlag = true;
				this.loadingHouse = true;
				this.personlist = [];
				houseinfolist({
					'x-access-token': this.GLOBAL.token(),
					"userid": this.GLOBAL.adminId(),
					'houseid': this.houseCode,
					'page': 1
				}).then((res) => {
					if (res.result == '200') {
						this.loadingHouse = false;
						this.housemsg = res.detail.houselist.list[0];
						this.masterlist = res.detail.masterlist;
						this.personlist = res.detail.personlist;
						let length = res.detail.showpicpage.personinfolist.length - 1;
						// console.log('length', length)
						let num = 0;
						if(length == -1){
							num = -1;
						}else{
							num = res.detail.showpicpage.personinfolist[length][0].accountcode;
						}
						// console.log('num', num)
						let list = res.detail.showpicpage.personinfolist;
						let personinfolist1 = [];
						let personinfolist2 = [];
						for(let item of list){
							if(item[0].accountcode.indexOf("_") != -1){
								personinfolist1.push({
									num:item[0].accountcode,
									list:item
								})
							}
						}
						console.log(res.detail.showpicpage.personinfolist);
						for(let i = 0;i<=num;i++){
							personinfolist2.push({
								num:i,
								list:[]
							})
							for(let item of res.detail.showpicpage.personinfolist){
								if(item[0].accountcode == i){
									personinfolist2[i].list = item
								}
							}
							
						}
						let personinfolist = personinfolist1.concat(personinfolist2);
						console.log('personinfolist', personinfolist)
						// this.$nextTick(() => {
							
						// })
						
						this.showpicpage = res.detail.showpicpage;
						this.showpicpage.personinfolist = personinfolist;
						console.log(this.showpicpage);
					} else {
						this.$message.error(res.description);
					}
				})

			},
			getTextOfVal(val) {
				if (val === 0) {
					return '户在人在'
				} else if (val === 1) {
					return '户在人不经常在'
				} else if (val === 2) {
					return '流动人口'
				} else if (val === 3) {
					return '户在人不在'
				} else {
					return '/'
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.create p {
		font-size: 18px;
		color: #1e9fff;
		// margin: 3px 0;
	}
	.produce {
		overflow: hidden;
		box-sizing: border-box;
		border-top: 1px solid #666;
		border-left: 1px solid #666;
		margin-top: 20px;
		.biaoqian{
			width: 100%;
			height: auto !important;
			padding: 5px 10px;
			ul{
				display: flex;
				flex-wrap: wrap;
				li{
					margin: 2px 4px 2px 0;
					color: #2a8cf1;
					border: 1px solid #2a8cf1;
					border-radius: 100px;
					padding: 2px 8px;
					line-height: 1.2em;
					font-size: 14px;
				}
			}
			
		}
	}
	
	.produce>div {
		float: left;
		width: 50%;
		height: 41px;
		box-sizing: border-box;
		border-bottom: 1px solid #666;
		border-right: 1px solid #666;
		line-height: 40px;
		padding: 0 10px;
	}
	.detailtable {
		overflow: hidden;
		border-top: 1px solid #e3e4e3;
		border-left: 1px solid #e3e4e3;
		margin-bottom: 20px;

		.biaoqian {
			width: 100%;
			height: auto !important;
			padding: 5px 10px;

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					margin: 2px 4px 2px 0;
					color: #2a8cf1;
					border: 1px solid #2a8cf1;
					border-radius: 100px;
					padding: 2px 8px;
					line-height: 1.2em;
					font-size: 14px;
				}
			}

		}
	}

	.detailtable p,
	.detailtable div {
		float: left;
		box-sizing: border-box;
		border-right: 1px solid #e3e4e3;
		border-bottom: 1px solid #e3e4e3;

	}

	.detailtable p {
		height: 37px;
		line-height: 37px;
		background-color: #e1f3fd;
		width: 100%;
		margin: 0;
		padding-left: 8px;
		color: #5b93c1;
		font-size: 16px;

	}

	.detailtable div {
		height: 36px;
		line-height: 36px;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding: 0 10px;
		box-sizing: border-box;
	}

	.detailtable .labelname {
		width: 8%;
	}

	.detailtable .biaoshi {
		text-align: left;

	}

	.allpeople {
		overflow: hidden;
		box-sizing: border-box;
		border: 1px solid #e8e9e8;
		padding: 0 22px;

		>div:first-child {
			border-top: none !important;
		}

		.yihu {
			border-top: 1px solid #d2d2d2;
			padding: 28px 0 0;
			position: relative;
			padding-left: 120px;
			display: flex;
			flex-wrap: wrap;
			min-height: 100px;
			.title {
				text-align: center;
				font-size: 12px;
				color: #fff;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 90px;
				height: 90px;

				img {
					width: 50px;
					height: auto;
				}

				p {
					margin-top: 10px;
					color: #333;
				}
			}

			>.people:first-child {
				border-left: none !important;
			}

			.people {
				border-left: 1px dotted #e5e5e5;
				padding: 0 20px;
				margin-bottom: 28px;

				.head {
					display: inline-flex;
					flex-wrap: wrap;
					writing-mode: vertical-lr;
					position: relative;

					.img {
						overflow: hidden;
						width: 90px;
						height: 90px;
						margin: 0 10px;
						border-radius: 50%;
						cursor: pointer;

						img {
							display: block;
							width: 100%;
							height: auto;
						}
					}

					.huise {
						background-color: #e8e9e8;
					}

					.lanse {
						background-color: #259cef;
					}

					p {
						line-height: 28px;
						text-align: center;
						font-size: 16px;
						color: #878787;
						// width: 96px;
						writing-mode: horizontal-tb;
					}

					.tag {
						box-sizing: border-box;
						writing-mode: horizontal-tb;
						border-radius: 200px;
						margin: 2px 5px;
						border: 1px solid #666;
						height: 20px;
						line-height: 18px;
						padding: 0 5px;
						background-color: #fff;
						color: #666;
						font-size: 14px;
						position: absolute;
						right: 0;
						transform: translateX(60%);
						top: -10px;

						&.blue {
							background-color: #409eff;
							border: 1px solid #409eff;
							color: #fff;

						}
					}
				}
			}
		}
	}

	.alldata {
		.hang {
			display: flex;
			flex-wrap: wrap;

			div {
				margin: 0 18px 10px 0;
				height: 30px;
				line-height: 30px;
				border-radius: 500px;
				padding: 0 15px;
				font-size: 16px;
			}

		}

		.one {
			div {
				background-color: #00a0e9;
				color: #fff;
			}
		}

		.two {
			div {
				background-color: #e5f3ff;
				color: #48a8d7;
				cursor: pointer;
			}

			.selected {
				background-color: #00a0e9;
				color: #fff;
			}
		}

		.three {
			display: flex;
			justify-content: end;
			cursor: pointer;

			div {
				box-sizing: border-box;
				border: 1px solid #666;
				background-color: #fff;
				color: #666;
				line-height: 28px !important;
			}

			.highblue {
				background-color: #00a0e9;
				color: #fff;
			}
		}
	}

	.personsbox {
		display: flex;

		.huzhuinfo {
			width: 36%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			&::after,
			&::before {
				position: absolute;
				content: '';
				width: 0;
				height: 0;
				border: 10px solid #fff;
				border-right-color: #119CF0;
				right: -1px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 5;
			}

			&::before {
				border: 8px solid transparent;
				border-right-color: #fff;
				z-index: 10;
			}
		}

		.fz_item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.head {
				margin-bottom: 40px;
				width: 90px;
				height: 90px;
				text-align: center;
				line-height: 90px;
				position: relative;
				background-color: #f0f0f0;
				border-radius: 50%;

				.iconsex {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						height: 80%;
						width: auto;
					}
				}

				i {
					font-size: 50px;
					line-height: 90px;
				}

				>img {
					position: absolute;
					bottom: -20px;
					left: 50%;
					transform: translateX(-50%);
					width: auto;
					height: 40px;
				}

				&+div {
					font-size: 14px;
					color: #333;
				}
			}

			ul {
				li {
					line-height: 1.6em;

					span {
						width: 90px;
						text-align: right;
						display: inline-block;
					}
				}
			}
		}

		.zhuhuinfo {
			margin-top: 20px;
			padding: 20px 0 20px 100px;
			border-left: 1px solid #119CF0;
			position: relative;

			&::before,
			&::after {
				width: 50px;
				height: 1px;
				content: "";
				background-color: #119CF0;
				position: absolute;
				left: 0;
			}

			&::before {
				top: 0;
			}

			&::after {
				bottom: 0;
			}

			.zh_item {
				position: relative;

				&:last-child {
					border-bottom: 0 none;
				}

				.head {
					width: 60px;
					height: 60px;
					text-align: center;
					line-height: 60px;
					position: absolute;
					left: - 20px;
					top: 50%;
					transform: translateY(-90%);
					background-color: #f0f0f0;
					border-radius: 50%;

					.iconsex {
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							height: 80%;
							width: auto;
						}
					}

					i {
						font-size: 20px;
						line-height: 60px;
					}

					p {
						position: absolute;
						font-size: 14px;
						width: 80px;
						text-align: center;
						color: #333;
						left: 50%;
						line-height: 1.6em;
						border: 1px solid #119CF0;
						transform: translateX(-50%);
						bottom: -30px;
						background-color: #fff;
						border-radius: 6px;
					}
				}

				ul {
					margin: 20px 0;
					padding: 25px 50px 25px 30px;
					background-color: #fff;
					border: 1px solid #119CF0;

					li {
						line-height: 1.6em;
						font-size: 14px;

						span {
							width: 120px;
							text-align: right;
							display: inline-block;
						}
					}
				}
			}


		}
	}
</style>